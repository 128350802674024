export default {
buildTime: "2022-09-01T20:57:29Z",
commit: "74a1d67318042a476ff3e2ecc926ab6aacf07224",
npmInfo:
{
  mashlib: '1.8.3',
  npm: '8.18.0',
  node: '14.17.3',
  v8: '8.4.371.23-node.67',
  uv: '1.41.0',
  zlib: '1.2.11',
  brotli: '1.0.9',
  ares: '1.17.1',
  modules: '83',
  nghttp2: '1.42.0',
  napi: '8',
  llhttp: '2.1.3',
  openssl: '1.1.1k',
  cldr: '39.0',
  icu: '69.1',
  tz: '2021a',
  unicode: '13.0'
}
};
